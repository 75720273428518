import React from "react";
import '../css/gin-tonic-item.css'
import cleanIngredienti from '../utils/utils.js'

export default class SimpleFoodItem extends React.Component {

    render() {

        const item = this.props.item
        let le = this.props.language_extension

        let ingredienti = item['ingredienti' + le] !== undefined ? item['ingredienti' + le] : item['ingredienti']

        return (
            ('ingredienti' in item && ingredienti.length > 1) && <>
                <div className="row" id="gin-tonic-item">
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'INGREDIENTI' : 'INGREDIENTS'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">{cleanIngredienti(ingredienti)}</span>
                    </div>
                </div>
            </>
        )
    }

}