import React, {useContext} from "react";
import { NavLink, useParams } from "react-router-dom";
import MenuItemDot from "../components/MenuItemDot";
import '../css/beverage.css'
import { ClemContext } from "../App.js";

let cats = {
    "ITA": {
        "birra-alla-spina": "Birra alla spina",
        "birra-in-bottiglia": "Birra in bottiglia",
        "distillati": "Distillati",
        "gin-tonic": "Gin & Tonic",
        "grappe-amari": "Grappe e Amari",
        "vino": "vini",
        "shots": "shots",
        "bibite-caffetteria": "Bibite, caffetteria",
        "aperitivi-cocktails": "Aperitivi & Cocktails",
        "hamburger": "hamburger",
        "pizza": "pizza",
        "cucina": "cucina",
        "panino-piadina": "Panini Scrocchiarella",
        "snack": "snack",
        "dessert": "dessert",
        "allergeni": "allergeni"
    },
    "ENG": {
        "birra-alla-spina": "Draft beer",
        "birra-in-bottiglia": "Bottled beer",
        "distillati": "Distillates",
        "gin-tonic": "Gin & Tonic",
        "grappe-amari": "Grappas & Bitters",
        "vino": "Wines",
        "shots": "shots",
        "bibite-caffetteria": "Cafeteria",
        "aperitivi-cocktails": "Aperitivi & Cocktails",
        "hamburger": "hamburger",
        "pizza": "pizza",
        "cucina": "our dishes",
        "panino-piadina": "Sandwiches-Piadina",
        "snack": "snack",
        "dessert": "dessert",
        "allergeni": "Allergens list"
    }
}

const fixed_order = {
    "food": [
        "cucina",
        "hamburger",
        "pizza",
        "panino-piadina",
        "snack",
        "dessert"
    ],
    "beverage": [
        "birra-alla-spina",
        "birra-in-bottiglia",
        "vino",
        "aperitivi-cocktails",
        "gin-tonic",
        "distillati",
        "grappe-amari",
        "shots",
        "bibite-caffetteria"
    ]
}

export default function MacroCategory() {

    const [itemsList, language, updateStatStore, eventsList] = useContext(ClemContext)
    const params = useParams()
    const macroCategory = params.macro_category

    return (
        <div className="container-fluid menu h-100 pb-5" id="clem-menu-beverage">
            <h1 className="text-center my-4">{macroCategory}</h1>

            <div>{(itemsList !== null && macroCategory in itemsList) && fixed_order[macroCategory].map((cat, key) => <MenuItemDot key={key} parent={macroCategory} path={cat} name={cats[language === "ITA" ? "ITA" : "ENG"][cat]} />)}</div>

            <NavLink className="clem-menu-back text-center w-100" to="/menu">Go back</NavLink>
        </div>
    )

}