import React from "react";
import '../css/gin-tonic-item.css'

export default class GABItem extends React.Component {

    render() {
        const item = this.props.item

        let le = this.props.language_extension
        let descrizione = item['descrizione' + le] !== undefined ? item['descrizione' + le] : item['descrizione']

        return (
            descrizione in item && descrizione.length > 1 && <div className="row" id="gin-tonic-item">
                <div className="col-12 mt-2" id="label">
                    {this.props.language === 'ITA' ? 'DESCRIZIONE' : 'DESCRIPTION'}
                </div>
                <div className="col-12">
                    <span className="fst-italic">{descrizione}</span>
                </div>
            </div>
        )
    }

}