import './style.css'
import Icon from './beer.png'

export default function BeerItemePrices({ prices }) {

    return <div className="row" id="beer">
        <div className='col-12' id="label">
            PREZZO
        </div>
        <div className='col-12'>
            <div className='row d-flex flex-row justify-content-center'>
                {prices.map((price, index) => <div key={index} className={'col-auto ' + 12 / prices.length + ' d-flex flex-row justify-content-center align-items-center px-1'}>
                    <img className="me-1" id={"beer-icon-" + index} src={Icon} />
                    <span id="price-value">{price['label'] !== "-1" && price['label']}l - {price['value']}€</span>
                </div>)}
            </div>
        </div>
    </div>

}