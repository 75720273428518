import React from "react";
import { NavLink } from "react-router-dom";
import '../css/menu-item-dot.css'


export default function MenuItemDot(props) {
    
    var path = "/menu/" + props.parent + "/" + props.path

    if (props.dot)
        return (
            <NavLink to={path} className="row justify-content-center" id="menu-item-dot">
                <div className="row justify-content-center">
                    {props.name}
                </div>
            </NavLink>
        )
    else
        return (
            <NavLink to={path} className="row justify-content-center" id="menu-item-dot">
                <div className="row justify-content-center">
                    {props.name}
                </div>
                <div className="row my-1 dot justify-content-center"></div>
            </NavLink>
        )

}