import React, { useContext, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Item from "../components/Item";
import '../css/category.css'
import { ClemContext } from "../App.js";
import MenuItemDot from "./MenuItemDot";

export default function DistillatiCategory() {

    const [itemsList, language, updateStatStore, eventsList] = useContext(ClemContext)
    const params = useParams()
    const macroCategory = params.macro_category
    const category = params.class

    const [items, setItems] = useState(null)

    useEffect(() => {
        if (itemsList !== null && macroCategory in itemsList && category in itemsList[macroCategory]){
            console.log(itemsList[macroCategory][category])
            setItems(itemsList[macroCategory][category])
        }
    })

    return (
        <div className="container-fluid menu h-100 pb-5" id="clem-menu-beverage">
            <h1 className="text-center my-4">{category}</h1>

            <div>
                {items !== null && Object.keys(items).map((cat, key) => <><NavLink key={key} to={"/menu/" + macroCategory + "/" + category + "/" + cat.toLowerCase()} className="row justify-content-center" id="menu-item-dot">
                    <div className="row justify-content-center">
                        {cat}
                    </div>
                    <div className="row my-1 dot justify-content-center"></div>
                </NavLink>
                </>)}
            </div>

            <NavLink className="clem-menu-back text-center w-100" to={"/menu/" + macroCategory}>Go back</NavLink>
        </div>
    )
}