import React from "react";
import '../css/gin-tonic-item.css'

export default class CucinaFoodItem extends React.Component {

    render() {

        const item = this.props.item

        let le = this.props.language_extension
        let ingredienti = item['ingredienti' + le] !== undefined ? item['ingredienti' + le] : item['ingredienti']
        let abbinamento_birra = item['abbinamento_birra' + le] !== undefined ? item['abbinamento_birra' + le] : item['abbinamento_birra']
        let abbinamento_vino = item['abbinamento_vino' + le] !== undefined ? item['abbinamento_vino' + le] : item['abbinamento_vino']

        return (
            <div className="row" id="gin-tonic-item">

                {/* INGREDIENTI */}
                {('ingredienti' in item && ingredienti.length > 1) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'INGREDIENTI' : 'INGREDIENTS'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">{ingredienti}</span>
                    </div>
                </>}

                {/* ABBINAMENTO BIRRA */}
                {('abbinamento_birra' in item && abbinamento_birra.length > 1) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'BIRRE CONSIGLIATE' : 'SUGGESTED BEERS'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">{abbinamento_birra}</span>
                    </div>
                </>}

                {/* ABBINAMENTO VINO */}
                {('abbinamento_vino' in item && abbinamento_vino.length > 1) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'VINI CONSIGLIATE' : 'SUGGESTED WINES'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">{abbinamento_vino}</span>
                    </div>
                </>}
            </div>
        )
    }

}