import React from "react";
import '../css/gin-tonic-item.css'

export default class GinTonicItem extends React.Component {

    render() {

        const item = this.props.item

        let le = this.props.language_extension
        let botaniche = item['botaniche' + le] !== undefined ? item['botaniche' + le] : item['botaniche']

        return (
            <div className="row" id="gin-tonic-item">
                {("botaniche" in item && botaniche.length > 1) && (
                    <div>
                        <div className="col-12 mt-2" id="label">
                            {this.props.language === 'ITA' ? 'DESCRIZIONE' : 'DESCRIPTION'}
                        </div>
                        <div className="col-12">
                            <span className="fst-italic">{botaniche}</span>
                        </div>
                    </div>
                )}
            </div>
        )
    }

}