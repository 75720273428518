import React from "react";
import '../css/gin-tonic-item.css'

export default class GABItem extends React.Component {

    render() {
        //const item = this.props.item

        return (
            <div>
            </div>
        )
    }

}