import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import '../css/category.css'
import { ClemContext } from "../App.js";
import EventItem from "../components/EventItem/EventItem";


export default function Events(props) {

    const [itemsList, language, updateStatStore, eventsList] = useContext(ClemContext)

    return (

        <div className="container-fluid menu h-100 d-flex flex-column align-items-center" id="clem-menu-beverage">
            <h1 className="text-center my-4">Events</h1>
            {eventsList !== null && eventsList.map((event, index) =>
                <EventItem key={index} item={event} language={language} />
            )}
            <NavLink className="clem-menu-back text-center w-100" to={"/"}>Go back</NavLink>
        </div>
    )

}