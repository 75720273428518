import React from "react";
import './style.css'

export default function EventItem({ item, language }) {

    let language_extension = language === 'ITA' ? '' : '_eng'

    return <div className="container-fluid px-0 can-scroll" id="clem-item">
        <div className="row d-flex flex-column align-items-center" id="event-item">

            {/* Item name */}
            <div className="col-12 text-center">
                <div className="title">{item['name' + language_extension] !== undefined ? item['name' + language_extension] : item['name']}</div>
            </div>

            {/* Image block (if specified by the user) */}
            <div className="col-10 my-3 text-center">
                <img alt={item.logo} style={{height: parseInt(window.innerHeight*0.65)}} className="img-event" src={item.logo}></img>
            </div>

            {/* Item date */}
            <div className="col-12 text-center">
                
            </div>
            
            {/* Item description */}
            <div className="col-12 text-center">
                <div className="col-12" id="value-big">
                    {item['data' + language_extension] !== undefined ? item['data' + language_extension] : item['data']}
                </div>
            </div>

            {/* Item description */}
            <div className="col-12 text-center">
                <div className="col-12" id="value">
                    {(item['descrizione' + language_extension] !== undefined) ? (!item['descrizione' + language_extension].includes("https://storage") && item['descrizione' + language_extension]) : (!item['descrizione' + language_extension].includes("https://storage") && item['descrizione'])}
                </div>
            </div>
        </div>
    </div >
}