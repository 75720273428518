import React from "react";
import '../css/item.css'
import BeerItem from "./BeerItem";
import BeerItemBottle from "./BeerItemBottle";
import DistillatiItem from "./DistillatiItem";
import DistillatiPrices from "./DistillatiPrices/DistillatiPrices";
import GinTonicItem from "./GinTonicItem";
import GinTonicPrices from "./GinTonicPrices/GinTonicPrices";
import GABItem from "./GABItem";
import GrappeAmariItem from "./GrappeAmariItem";
import GrappeAmariPrices from "./GrappeAmariPrices/GrappeAmariPrices";
import DrinksItem from "./DrinksItem";
import DrinksPrices from "./DrinksPrices/DrinksPrices";
import SimpleFoodItem from "./SimpleFoodItem";
import HamburgerFoodItem from "./HamburgerFoodItem";
import CucinaFoodItem from "./CucinaFoodItem";
import PizzaFoodItem from "./PizzaFoodItem";
import ShotsItem from "./ShotsItem";
import WineItem from "./WineItem";
import WinesPrices from "./WinesPrices/WinesPrices";
import BeerItemBottlePrices from "./BeerItemBottlePrices/BeerItemBottlePrices";
import BeerItemePrices from "./BeerItemPrices/BeerItemPrices";
import PaniniPiadinePrices from "./PaniniPiadinePrices/PaniniPiadinePrices";

export default function Item({ item, language, isFirst, section_name }) {

    const item_class = item['class']

    if (item_class === "hamburger") {
        var subclass = item['tipologia'].toLowerCase()
        subclass = subclass.split(' ')[0].replace(' ', '')
        item['image_url'] = "https://storage.googleapis.com/clem-website/icons_burger/" + subclass + ".png"
    }

    const map = {
        "birra-alla-spina": BeerItem,
        "birra-in-bottiglia": BeerItemBottle,
        "distillati": DistillatiItem,
        "gin-tonic": GinTonicItem,
        "grappe-amari": GrappeAmariItem,
        "aperitivi-cocktails": DrinksItem,
        "shots": ShotsItem,
        "vino": WineItem,
        "bibite-caffetteria": GABItem,
        "hamburger": HamburgerFoodItem,
        "panino-piadina": SimpleFoodItem,
        "antipasto": SimpleFoodItem,
        "primo": GABItem,
        "secondo": GABItem,
        "dessert": GABItem,
        "snack": GABItem,
        "pizza": PizzaFoodItem,
        "cucina": CucinaFoodItem,
        "insalata": SimpleFoodItem
    }

    const priceMap = {
        "birra-alla-spina": BeerItemePrices,
        "birra-in-bottiglia": BeerItemBottlePrices,
        "distillati": DistillatiPrices,
        "gin-tonic": GinTonicPrices,
        "grappe-amari": GrappeAmariPrices,
        "aperitivi-cocktails": DrinksPrices,
        "shots": DrinksPrices,
        "vino": WinesPrices,
        "bibite-caffetteria": PaniniPiadinePrices,
        "hamburger": PaniniPiadinePrices,
        "panino-piadina": PaniniPiadinePrices,
        "antipasto": PaniniPiadinePrices,
        "primo": PaniniPiadinePrices,
        "secondo": PaniniPiadinePrices,
        "dessert": PaniniPiadinePrices,
        "snack": PaniniPiadinePrices,
        "pizza": PaniniPiadinePrices,
        "cucina": PaniniPiadinePrices,
        "insalata": PaniniPiadinePrices
    }

    let Tag = map[item_class]
    let PriceTag = priceMap[item_class]
    let language_extension = language === 'ITA' ? '' : '_eng'
    let allergeni = item['allergeni' + language_extension] !== undefined ? item['allergeni' + language_extension] : item['allergeni']

    return (
        <div className="container-fluid px-0 text-center" id="clem-item">

            {isFirst === 0 && section_name !== "standard" ? <div className="row mb-3" id="section_name"><div className="col-12">{section_name}</div></div> : <span></span>}

            {/* Item header */}
            <div className="row d-flex flex-column align-items-center mb-4">

                {/* Item name */}
                <div className="col-12 text-center">
                    <div className="title">{item['name' + language_extension] !== undefined ? item['name' + language_extension] : item['name']}</div>
                </div>

                {/* NEW tag */}
                {item.hasOwnProperty("is_new") && item["is_new"] === true && <div className="col-12 mt-2 d-flex flex-row justify-content-center align-items-center">
                    <div className="badge-new rounded-pill">NEW</div>
                </div>}

                {/* Image block (if specified by the user) */}
                {('image_url' in item && item['image_url'].length > 1) && <div className="col-7 text-center mt-3" hidden={item.hasOwnProperty("image_url") && item["image_url"] !== "-" ? false : true}>
                    <img alt={item['image_url']} id="capped-image" className="img-fluid" src={item['image_url']}></img>
                </div>}

                {/* Additional info customized based on the type */}
                <div className="col-12 mt-3 text-center">
                    <Tag language={language} language_extension={language_extension} item={item} />
                </div>
                
                {/* Allergeni */}
                {("allergeni" in item && allergeni.length > 1) && <div className="text-center">
                    <div className="col-12 mt-2" id="allergeni-title">
                        {language === 'ITA' ? 'ALLERGENI' : 'ALLERGENS'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">{allergeni}</span>
                    </div>
                </div>}

            </div>

            {/* Prices */}
            {item_class !== "pizza" && <PriceTag language={language} prices={item['prices']} />}

            <hr className="mt-4"></hr>
        </div >

    )
}