import React from "react";
import '../css/whiskey-item.css'

export default function DistillatiItem({ item, language, language_extension }) {

    let le = language_extension

    return (
        <div className="row" id="beer-item">
            <div className="col-12">
                <span className="fst-italic">{item['tipologia' + le] !== undefined ? item['tipologia' + le] : item['tipologia']}</span>
                <span className="mx-2"> | </span>
                <span className="fst-italic">{item['provenienza' + le] !== undefined ? item['provenienza' + le] : item['provenienza']}</span>
            </div>
            
            {/* Descrizione */}
            {Object.keys(item).includes('note') && <>
                <div className="col-12 mt-2" id="label">
                    {language === 'ITA' ? 'NOTE' : 'NOTES'}
                </div>
                <div className="col-12">
                    <span className="fst-italic">{item['note' + le] !== undefined ? item['note' + le] : item['note']}</span>
                </div>
            </>}

            {/* Note */}
            {Object.keys(item).includes('note') && <>
                <div className="col-12 mt-2" id="label">
                    {language === 'ITA' ? 'NOTE' : 'NOTES'}
                </div>
                <div className="col-12">
                    <span className="fst-italic">{item['note' + le] !== undefined ? item['note' + le] : item['note']}</span>
                </div>
            </>}
        </div>
    )

}