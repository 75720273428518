import './style.css'

export default function GinTonicPrices({ prices, language }) {

    return <div className="row" id="gin-tonic-price">

        {/* First section */}
        <div className="col-12" id="label">GIN &amp; TONIC</div>
        <div className="col-12"><span id="price-value">{prices[0].value}€</span>{language === 'ITA' ? 'al bicchiere' : 'per glass'}</div>

        {/* Second section */}
        {(prices.length > 1 && prices[1].value > 0) && <><div className="col-12 mt-3" id="label">SEVEN TONIC BOTTLE</div>
        <div className="col-12">
            <span id="price-value">{prices[1].value}€</span>{language === 'ITA' ? 'a combo' : 'per combo'}
        </div></>}
        
    </div>

}