import React from "react";
import '../css/beer-item.css'

export default class WineItem extends React.Component {

    render() {

        const item = this.props.item
        let le = this.props.language_extension

        let vitigno = item['vitigno' + le] !== undefined ? item['vitigno' + le] : item['vitigno']
        let descrizione = item['descrizione' + le] !== undefined ? item['descrizione' + le] : item['descrizione']
        let abbinamento = item['abbinamento' + le] !== undefined ? item['abbinamento' + le] : item['abbinamento']

        return (
            <div className="row" id="beer-item">

                {/* VITIGNO */}
                {('vitigno' in item && vitigno.length > 1) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'VITIGNO' : 'GRAPE VARIETY'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">
                            {vitigno}
                        </span>
                    </div>
                </>
                }

                {/* DESCRIZIONE */}
                {('descrizione' in item && descrizione.length > 1) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'descrizione' : 'description'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">
                            {descrizione}
                        </span>
                    </div>
                </>}

                {/* ABBINAMENTO */}
                {('abbinamento' in item && abbinamento.length > 1) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'ABBINAMENTO' : 'COMBINATIONS'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">
                            {abbinamento}
                        </span>
                    </div>
                </>}

            </div>
        )
    }

}