import { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router";
import { ClemContext } from "../../App.js";
import Close from "../../images/close.png";
import './style.css'

export default function Popup({ children }) {

    const [itemsList, language, updateStatStore, eventsList] = useContext(ClemContext)
    const [show, setShow] = useState(true)
    let language_extension = language === 'ITA' ? '' : '_eng'

    let [event, setEvent] = useState(null)
    let [currentIndex, setCurrentIndex] = useState(0)

    useEffect( () => {
        if (eventsList !== null && eventsList.length > 0){
            setEvent(eventsList[currentIndex])
        }
    }, [currentIndex, eventsList])

    return <>
        {(event !== null && show === true) && <div onClick={() => (currentIndex < (eventsList.length-1) ? setCurrentIndex(currentIndex+1) : setShow(false))} className="popup d-flex flex-column align-items-center justify-content-center p-3" id="clem-item">
            <div className="w-100 title text-center pt-3 pb-1">
                {event['name' + language_extension] !== undefined ? event['name' + language_extension] : event['name']}
            </div>

            <div className="w-100 text-center date pb-2">
                {event['data' + language_extension] !== undefined ? event['data' + language_extension] : event['data']}
            </div>

            <div className="text-center" style={{ height: window.innerHeight - 200 }}>
                <img className="popup-image" src={event['logo' + language_extension] !== undefined ? event['logo' + language_extension] : event['logo']} />
            </div>
            <div className="text-center">
                <img className="close-icon mt-4" src={Close} />
            </div>
        </div>}
        {children}
    </>

}