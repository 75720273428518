import React from "react";
import '../css/beer-item.css'

export default class BeerItem extends React.Component {

    render() {

        const item = this.props.item
        let le = this.props.language_extension

        let birrificio = item['birrificio' + le] !== undefined ? item['birrificio' + le] : item['birrificio']
        let origine = item['origine' + le] !== undefined ? item['origine' + le] : item['origine']
        let fermentazione = item['fermentazione' + le] !== undefined ? item['fermentazione' + le] : item['fermentazione']
        let spillatura = item['spillatura' + le] !== undefined ? item['spillatura' + le] : item['spillatura']
        let descrizione = item['descrizione' + le] !== undefined ? item['descrizione' + le] : item['descrizione']
        let abbinamento = item['abbinamento' + le] !== undefined ? item['abbinamento' + le] : item['abbinamento']

        return (
            <div className="row" id="beer-item">

                {/* SHORT INFO */}
                <div className="col-12">
                    <span className="fst-italic">{item['tipologia' + le] !== undefined ? item['tipologia' + le] : item['tipologia']}</span>
                    <span className="mx-2"> | </span>
                    <span className="fst-italic">{item['gradazione']}%</span>
                    <span className="mx-2"> | </span>
                    <span className="fst-italic">{item['colore' + le] !== undefined ? item['colore' + le] : item['colore']}</span>
                </div>

                {/* BIRRIFICIO */}
                {('birrificio' in item && birrificio.length > 1) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'BIRRIFICIO' : 'BREWERY'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">
                            {birrificio}
                        </span>
                    </div>
                </>}

                {/* ORIGINE */}
                {('origine' in item && origine.length > 1) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'ORIGINE' : 'NATIONALITY'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">
                            {origine}
                        </span>
                    </div>
                </>}

                {/* FERMENTAZIONE */}
                {('fermentazione' in item && fermentazione.length > 1) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'FERMENTAZIONE' : 'fermentation'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">
                            {fermentazione}
                        </span>
                    </div>
                </>}

                {/* SPILLATURA */}
                {('spillatura' in item && spillatura.length > 1) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'spillatura' : 'tapping'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">
                            {spillatura}
                        </span>
                    </div>
                </>}

                {/* DESCRIZIONE */}
                {('descrizione' in item && descrizione.length > 1) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'descrizione' : 'description'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">
                            {descrizione}
                        </span>
                    </div>
                </>}

                {/* ABBINAMENTO */}
                {('abbinamento' in item && abbinamento.length > 1) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'ABBINAMENTI' : 'COMBINATIONS'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">
                            {abbinamento}
                        </span>
                    </div>
                </>}
            </div>
        )
    }

}