import './style.css'

export default function PaniniPiadinePrices({ prices, language }) {

    return <div className="row" id="gin-tonic-price">

        {/* First section */}
        {(prices.length > 0 && prices[0].value > 0) && <div className="col-12"><span id="price-value">{prices[0].value}€</span></div>}
        
    </div>

}