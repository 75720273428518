import './style.css'

export default function WinesPrices({ prices, language }) {

    return <div className="row" id="gin-tonic-price">

        {/* First section */}
        {(prices.length > 0 && prices[0].value > 0) && <div className="col-12"><span id="price-value">{prices[0].value}€</span>{language === 'ITA' ? 'al bicchiere' : 'per glass'}</div>}

        {/* Second section */}
        {(prices.length > 1 && prices[1].value > 0) && <div className="col-12"><span id="price-value">{prices[1].value}€</span>{language === 'ITA' ? 'a bottiglia' : 'per bottle'}</div>}
        
    </div>

}