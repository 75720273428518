import React from "react";
import '../css/gin-tonic-item.css'

export default class DrinksItem extends React.Component {

    render() {

        const item = this.props.item

        let le = this.props.language_extension
        let botaniche = item['Botaniche' + le] !== undefined ? item['Botaniche' + le] : item['Botaniche']
        let ingredienti = item['ingredienti' + le] !== undefined ? item['ingredienti' + le] : item['ingredienti']

        return (
            <div className="row" id="gin-tonic-item">
                <div className="col-12" id="subtitle">
                    <span className="fst-italic">{item['tipologia' + le] !== undefined ? item['tipologia' + le] : item['tipologia']}</span>
                    <span className="mx-2"> | </span>
                    <span className="fst-italic">{item['tipologia-2' + le] !== undefined ? item['tipologia-2' + le] : item['tipologia-2']}</span>
                </div>

                {("ingredienti" in item && ingredienti.length > 1) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'INGREDIENTI' : 'INGREDIENTS'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">{ingredienti}</span>
                    </div>
                </>}

                {("botaniche" in item && botaniche.length > 1) && (
                    <div>
                        <div className="col-12 mt-2" id="label">
                            {this.props.language === 'ITA' ? 'BOTANICHE' : 'BOTANICALS'}
                        </div>
                        <div className="col-12">
                            <span className="fst-italic">{botaniche}</span>
                        </div>
                    </div>
                )}
            </div>
        )
    }

}