import React, { useContext, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Item from "../components/Item";
import '../css/category.css'
import { ClemContext } from "../App.js";
import DistillatiCategory from "../components/DistillatiCategory";

let cats = {
    "ITA": {
        "birra-alla-spina": "Birra alla spina",
        "birra-in-bottiglia": "Birra in bottiglia",
        "distillati": "Distillati",
        "gin-tonic": "Gin & Tonic",
        "grappe-amari": "Grappe e Amari",
        "vino": "vini",
        "shots": "shots",
        "bibite-caffetteria": "Bibite, caffetteria",
        "aperitivi-cocktails": "Aperitivi & Cocktails",
        "hamburger": "hamburger",
        "pizza": "pizza",
        "cucina": "cucina",
        "panino-piadina": "Panini Scrocchiarella",
        "snack": "snack",
        "dessert": "dessert",
        "allergeni": "allergeni"
    },
    "ENG": {
        "birra-alla-spina": "Draft beer",
        "birra-in-bottiglia": "Bottled beer",
        "distillati": "Distillates",
        "gin-tonic": "Gin & Tonic",
        "grappe-amari": "Grappas & Bitters",
        "vino": "Wines",
        "shots": "shots",
        "bibite-caffetteria": "Cafeteria",
        "aperitivi-cocktails": "Aperitivi & Cocktails",
        "hamburger": "hamburger",
        "pizza": "pizza",
        "cucina": "our dishes",
        "panino-piadina": "Sandwiches-Piadina",
        "snack": "snack",
        "dessert": "dessert",
        "allergeni": "Allergens list"
    }
}


export default function Category() {

    const [itemsList, language, updateStatStore, eventsList] = useContext(ClemContext)
    const params = useParams()
    const macro_category = params.macro_category
    const category = params.class
    const subcategory = params.subclass !== undefined ? params.subclass : null

    console.log(category, subcategory)

    const [items, setItems] = useState(null)
    const [sections, setSections] = useState(null)

    useEffect(() => {

        if (itemsList !== null && macro_category in itemsList && category in itemsList[macro_category])
            if (subcategory !== null) {
                setSections(itemsList[macro_category][category][subcategory]["sections"][language])
                setItems(itemsList[macro_category][category][subcategory])
            } else if (Object.keys(itemsList[macro_category][category]).includes("sections")) {
                setSections(itemsList[macro_category][category]["sections"][language])
                setItems(itemsList[macro_category][category])
            }

    }, [itemsList])

    function getCategory(cat) {

        return cats[language][cat]

    }

    return (category === "distillati" && subcategory === null) ? <DistillatiCategory /> : <div className="container-fluid menu h-100" id="clem-menu-beverage">
        <h1 className="text-center my-4">{getCategory(subcategory === null ? category : subcategory)}</h1>

        {(category === "hamburger") && <h5 className="text-center my-4" style={{ color: "#ccc", fontStyle: "italic" }}>{language === 'ITA' ? '- serviti con patatine fritte -' : '- served with chips -'}</h5>}

        {(items !== null && sections !== null) && sections.map((section, index) =>
            items[section].map((item, index2) => <Item key={index2} isFirst={index2} section_name={section} item={item} language={language} />)
        )}

        {(category === "pizza") || (category === "hamburger") || (category === "cucina") && <h5 className="text-center mt-3 mb-5" style={{ color: "#ccc", fontStyle: "italic" }}>Coperto: 1.5€</h5>}

        <NavLink className="clem-menu-back text-center w-100" to={"/menu/" + macro_category + (subcategory !== null ? ("/" + category) : "")}>Go back</NavLink>
    </div>

}