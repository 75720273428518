import React from "react";
import { NavLink } from "react-router-dom";
import '../css/menu.css'


export default function Menu() {

    return (
        <div className="container-fluid menu" id="clem-menu">
            <div className="row h-100">
                <div className="col-lg-6 h-lg-100 drinks m-0 p-0" id="beverage">
                    <div className="overlay h-100 w-100 m-0 p-0">
                        <NavLink className="button w-100 h-100 d-flex align-items-center justify-content-center" to="/menu/beverage">
                            Beverage
                        </NavLink>
                    </div>
                </div>
                <div className="col-lg-6 h-lg-100 m-0 p-0" id="foods">
                    <div className="overlay h-100 w-100 m-0 p-0">
                        <NavLink className="button w-100 h-100 d-flex align-items-center justify-content-center" to="/menu/food">
                            Food
                        </NavLink>
                    </div>
                </div>
            </div>
            <NavLink className="clem-menu-back-small text-center w-100" to="/">Go back</NavLink>
        </div>
    )

}