import { Route, BrowserRouter as Router, Switch, BrowserRouter } from "react-router-dom";
import './App.css';
import axios from "axios"
import Home from './templates/Home'
import Menu from './templates/Menu'
import Book from './templates/Book';
import Category from './templates/Category';
import { useEffect, useState, createContext } from "react";
import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid'
import { initializeApp } from "firebase/app";
import Popup from './components/Popup/Popup'
import { getFirestore, collection, getDocs, query, orderBy, doc, setDoc } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Events from "./templates/Events";
import MacroCategory from "./templates/MacroCategory";
import DistillatiCategory from "./components/DistillatiCategory";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyCdEC4XKID4mQ5SpiGXQlbhKfXpC2CS_w0",
  authDomain: "bright-calculus-276317.firebaseapp.com",
  projectId: "bright-calculus-276317",
  storageBucket: "bright-calculus-276317.appspot.com",
  messagingSenderId: "707805024062",
  appId: "1:707805024062:web:4cb8260d4cafc32bf8e66f"
});

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(firebaseApp);

var db = null

const statStore = {
  "session_id": uuid(),
  "session_start": Date.now(),
  "session_last_update": Date.now() + 1,
  "pages": {},
  "apiErrors": 0
}

export const ClemContext = createContext();

function App() {

  var tempLanguage = Cookies.get("clem-lang")
  if (!(tempLanguage !== undefined && (tempLanguage === 'ITA' || tempLanguage === 'ENG'))) {
    tempLanguage = 'ITA'
    Cookies.set("clem-lang", tempLanguage)
  }

  const [itemsList, setItemsList] = useState(null)
  const [eventsList, setEventsList] = useState(null)
  const [language, setLanguage] = useState(tempLanguage)
  const [hide, setHide] = useState(false)

  window.addEventListener('resize', () => {
    //setHide(window.innerHeight < window.innerWidth)
  });

  useEffect(() => {

    signInWithEmailAndPassword(auth, "website@clempub.it", "cola9999")
      .then((userCredential) => {
        db = getFirestore();
        console.log("SIGNED-IN", userCredential)

        downloadItems()

      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        console.error(errorCode, errorMessage)
      });

  }, [])

  async function call(operation, payload = {}, onSuccess = null, onFail = null) {

    try {
      let data = await axios.post('https://api.clempub.it/' + operation + '?ts=' + Math.floor(Math.random() * 1000000),
        payload, {
        headers: {
          "Content-type": "application/json"
        },
      })

      // Print in the console the exception
      if (data.status !== 200) {
        console.log("Error", payload)
        console.error(data.exception)
        console.error('------')
      }

      // If the call success and onSuccess object is different from null than execute the operation specified by the user
      if (data.status === 200 && onSuccess != null)
        onSuccess(data.payload)

      // If the call fails and onFail object is different from null than execute the operation specified by the user
      if (data.status !== 200 && onFail != null)
        onFail()

      return data.status === 200 ? data.data : null

    } catch (e) {
      console.error(e)
      return "KO"
    }
  }

  async function downloadItems() {

    try {
      let data = await call("get_items_tree", {}, () => console.log("Download completed!"), () => console.error("Download failed!"))

      let data2 = await call("get_events", {}, () => console.log("Download completed!"), () => console.error("Download failed!"))

      console.log(data2)

      console.log("Downloaded items: ", data)
      console.log("Downloaded events: ", data2.length)
      setItemsList(data)
      setEventsList(data2)
    } catch (e) {
      statStore['apiErrors'] = statStore['apiErrors'] + 1
      postStatStore()

      console.error(e)
    }

  }

  function updateStatStore(key) {

    var now = Date.now();

    if (!(key in statStore)) {
      statStore['pages'][key] = 1
      statStore['session_last_update'] = Date.now();
      postStatStore()
    }

    if ((now - statStore['session_last_update']) / 1000 > 2) {
      statStore['pages'][key]++
      statStore['session_last_update'] = Date.now();
      postStatStore()
    }

  }

  async function postStatStore() {
    try {
      await setDoc(doc(db, "stats", statStore['session_id']), statStore);
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <ClemContext.Provider value={[itemsList, language, updateStatStore, eventsList]}>
      {hide === false && <Router>
        <Popup>
          <Switch>
            <Route path="/health" render={(props) => <p>Ok!</p>} />
            <Route path="/">
              <BrowserRouter>
                <Route exact path="/" render={(props) => <Home setLanguage={setLanguage} {...props} />} />
                <Route exact path="/book" component={Book} />
                <Route exact path="/menu" component={Menu} />
                <Route exact path="/events" component={Events} />
                <Route exact path="/menu/:macro_category" component={MacroCategory} />
                <Route exact path="/menu/:macro_category/:class" component={Category} />
                <Route exact path="/menu/:macro_category/:class/:subclass" component={Category} />
              </BrowserRouter>
            </Route>
          </Switch>
        </Popup>
      </Router>}
      {hide === true && <div className="bg-dark w-100 h-100 d-flex justify-content-center align-items-center">
        Please, rotate your device in order to visualize the menu!
      </div>}
    </ClemContext.Provider>
  );
}

export default App;