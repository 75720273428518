import './style.css'
import Icon from './beer-bottle.png'

export default function BeerItemBottlePrices({ prices }) {

    return <div className="row">
        <div className='col-12' id="label">
            PREZZO
        </div>
        <div className='col-12 d-flex flex-row justify-content-center align-items-center'>
            <img id="beer-bottle-icon" src={Icon} />
            <span id="price-value">{prices[0]['label'] !== "-1" && prices[0]['label']} l - {prices[0]['value']} €</span>
        </div>
    </div>

}