import React from "react";
import '../css/gin-tonic-item.css'

export default class PizzaFoodItem extends React.Component {

    render() {

        const item = this.props.item

        console.log(item.prices)

        let le = this.props.language_extension
        let ingredienti = item['ingredienti' + le] !== undefined ? item['ingredienti' + le] : item['ingredienti']
        let abbinamento_birra = item['abbinamento_birra' + le] !== undefined ? item['abbinamento_birra' + le] : item['abbinamento_birra']
        let abbinamento_vino = item['abbinamento_vino' + le] !== undefined ? item['abbinamento_vino' + le] : item['abbinamento_vino']

        return (
            <div className="row" id="gin-tonic-item">

                {/* INGREDIENTI */}
                {('ingredienti' in item && ingredienti.length > 1) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'INGREDIENTI' : 'INGREDIENTS'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">{ingredienti}</span>
                    </div>
                </>}

                {/* PIZZA */}
                {('prices' in item && item.prices.length >= 1) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'PIZZA' : 'PIZZA'}
                    </div>
                    {(item.image_url !== undefined && item.image_url.length > 5) && <div className="col-6 text-center">
                        <img className="img-fluid" src={item.image_url} />
                    </div>}
                    <div className="col-12 prices">
                        <span id="price-value" className="fst-italic">{item.prices[0]['value']} €</span>
                    </div>
                </>}

                {/* SCROCCHIARELLA */}
                {('prices' in item && item.prices.length >= 2 && item.prices[1]['value'] > 0) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'SCROCCHIARELLA' : 'SCROCCHIARELLA'}
                    </div>
                    {(item.image_url2 !== undefined && item.image_url2.length > 5) && <div className="col-6 text-center">
                        <img className="img-fluid" src={item.image_url2} />
                    </div>}
                    <div className="col-12 prices">
                        <span id="price-value" className="fst-italic">{item.prices[1]['value']} €</span>
                    </div>
                </>}

                {/* ABBINAMENTO BIRRA */}
                {('abbinamento_birra' in item && abbinamento_birra.length > 1) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'BIRRE CONSIGLIATE' : 'SUGGESTED BEERS'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">{abbinamento_birra}</span>
                    </div>
                </>}

                {/* ABBINAMENTO VINO */}
                {('abbinamento_vino' in item && abbinamento_vino.length > 1) && <>
                    <div className="col-12 mt-2" id="label">
                        {this.props.language === 'ITA' ? 'VINI CONSIGLIATI' : 'SUGGESTED WINES'}
                    </div>
                    <div className="col-12">
                        <span className="fst-italic">{abbinamento_vino}</span>
                    </div>
                </>}

            </div>
        )
    }

}