import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import MenuItemDot from "../components/MenuItemDot";
import '../css/beverage.css'
import { ClemContext } from "../App.js";

export default function Beverage() {

    return (
        <div className="container-fluid menu h-100 pb-5" id="clem-menu-beverage">
            {/* <div class="quandoo-widget-builder" data-config='{"format":"text-button","bgcolor":"#f5b016","txcolor":"#ffffff","round":"yes","position":"","font":"md","merchant":93405,"txt":"Prenota ora"}'></div>
            <script src="https://s3-eu-west-1.amazonaws.com/quandoo-website/widget-builder/quandoo-widget-builder.js"></script> */}
        </div>
    )

}