import React from "react";
import '../css/home.css'
import { NavLink } from "react-router-dom";
import FacebookLogo from "../images/icons/facebook.png"
import InstagramLogo from "../images/icons/instagram.png"
import PhoneLogo from "../images/icons/phone.png"
import ITAFlag from "../images/icons/italy.png"
import ENGFlag from "../images/icons/united-kingdom.png"
import Cookies from 'js-cookie';

export default class Home extends React.Component {

    constructor(props) {
        super(props)

        var language = Cookies.get('clem-lang')

        this.state = {
            language: language,
            left: 0,
            originalOffset: 0,
            velocity: 0,
            timeOfLastDragEvent: 0,
            touchStartY: 0,
            prevtouchY: 0,
            beingTouched: false,
            height: 0,
            intervalId: null
        }
    }

    handleRemoveSelf() {
        this.setState({ height: 0 });
        window.setTimeout(() => this.props.onRemoval(), 250);
    }

    handleStart(clientY) {
        if (this.state.intervalId !== null) {
            window.clearInterval(this.state.intervalId);
        }
        this.setState({
            originalOffset: this.state.left,
            velocity: 0,
            timeOfLastDragEvent: Date.now(),
            touchStartY: clientY,
            beingTouched: true,
            intervalId: null
        });
    }

    handleMove(clientY) {
        if (this.state.beingTouched) {
            const touchY = clientY;
            const currTime = Date.now();
            const elapsed = currTime - this.state.timeOfLastDragEvent;
            const velocity = 20 * (touchY - this.state.prevtouchY) / elapsed;
            let deltaY = touchY - this.state.touchStartY + this.state.originalOffset;

            this.setState({
                left: deltaY,
                velocity,
                timeOfLastDragEvent: currTime,
                prevtouchY: touchY
            });
        }
    }

    handleEnd() {
        if (this.state.left > 50 || this.state.left < -50)
            this.props.history.push('/menu')
    }

    handleTouchStart(touchStartEvent) {
        touchStartEvent.preventDefault();
        this.handleStart(touchStartEvent.targetTouches[0].clientY);
    }

    handleTouchMove(touchMoveEvent) {
        this.handleMove(touchMoveEvent.targetTouches[0].clientY);
    }

    handleTouchEnd() {
        this.handleEnd();
    }

    handleMouseDown(mouseDownEvent) {
        mouseDownEvent.preventDefault();
        this.handleStart(mouseDownEvent.clientY);
    }

    handleMouseMove(mouseMoveEvent) {
        this.handleMove(mouseMoveEvent.clientY);
    }

    handleMouseUp() {
        this.handleEnd();
    }

    handleMouseLeave() {
        this.handleMouseUp();
    }

    setLanguage(lang) {
        this.props.setLanguage(lang)
        Cookies.set("clem-lang", lang)

        this.setState({
            language: lang
        })
    }

    render() {
        return (
            <div
                onTouchStart={touchStartEvent => this.handleTouchStart(touchStartEvent)}
                onTouchMove={touchMoveEvent => this.handleTouchMove(touchMoveEvent)}
                onTouchEnd={() => this.handleTouchEnd()}

                // The following event handlers are for mouse compatibility:
                onMouseDown={mouseDownEvent => this.handleMouseDown(mouseDownEvent)}
                onMouseMove={mouseMoveEvent => this.handleMouseMove(mouseMoveEvent)}
                onMouseUp={() => this.handleMouseUp()}
                onMouseLeave={() => this.handleMouseLeave()}

                className="container-fluid h-100" id="clem-home">

                <div className="row" id="firm">
                    <div className="col-md-auto col-12 text-center w-100 text-clem-white title">
                        Created by Andrea Cola, powered by Google Cloud
                    </div>
                </div>

                <div className="d-flex w-100" id="socials">
                    <a href="https://it-it.facebook.com/PubClemBormio/"><img alt="Facebook" className="h-100" src={FacebookLogo} /></a>
                    <a href="https://www.instagram.com/clempub.bormio/"><img alt="Instagram" className="h-100" src={InstagramLogo} /></a>
                    <a href="tel:3516625240"><img alt="Phone" className="h-100" src={PhoneLogo} /></a>
                </div>

                <div className="row w-100" id="languages">
                    {(this.state.language === 'ENG') ? (
                        <button onClick={() => this.setLanguage('ITA')} className="h-100 language-button" style={{ backgroundImage: `url(${ITAFlag})` }} />
                    ) : (
                        <button onClick={() => this.setLanguage('ENG')} className="h-100 language-button" style={{ backgroundImage: `url(${ENGFlag})` }} />
                    )}
                </div>

                <div className="row" id="chisiamo">
                    <div className="col-md-12 col-12 text-center text-clem-white title">
                        <NavLink className="menu-item-small w-100 text-center" to="/book">
                            {(this.state.language === 'ITA') ? "Chi siamo" : "About us"}
                        </NavLink>
                    </div>
                </div>

                <div className="row" id="eventi">
                    <div className="col-md-12 col-12 text-center text-clem-white title">
                        <NavLink className="menu-item-small w-100 text-center" to="/events">
                            {(this.state.language === 'ITA') ? "Eventi" : "Events"}
                        </NavLink>
                    </div>
                </div>

                <div className="row" id="menu">
                    <div className="col-md-12 col-12 text-center text-clem-white title">
                        <NavLink className="menu-item w-100 text-center" to="/menu">menu</NavLink>
                    </div>
                    <div className="col-md-12 col-12 text-center text-clem-white title icon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="24" height="24" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" /></svg>
                    </div>
                </div>

            </div>
        )
    }

}